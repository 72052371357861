import type { Schemas } from "#shopware";
import { SHIPPING_METHODS_LIST } from "~/lib/constants";

export const getExclusiveDeliveryMethodMessage = (
	product: Schemas["Product"] | Schemas["OrderLineItem"]["payload"] | Schemas["LineItem"]["payload"]
) => {
	if (!product) return;
	const customFields =
		"productCustomFields" in product
			? product.productCustomFields
			: "customFields" in product
				? product.customFields
				: (undefined as any);

	const methodIds =
		"kippie_product_fields_available_shipping_methods" in customFields &&
		customFields.kippie_product_fields_available_shipping_methods;
	if (!methodIds || methodIds.length > 1 || methodIds.length === 0) return;

	const exclusiveMethod = SHIPPING_METHODS_LIST.find((method) => method.id === methodIds[0]);
	if (!exclusiveMethod) return;

	return exclusiveMethod.technicalName === "delivery" ? "Alleen voor bezorging" : "Alleen in de winkel";
};
